import type { ZodError } from 'zod'

const handleValidationErrors = (errors: ZodError) => {
  const messages = Object.fromEntries(
    errors.errors.map(error => {
      const { path, message } = error
      return [path[0], message]
    }),
  )
  return messages
}

export { handleValidationErrors }
